
          @import "@/assets/scss/mixins/absolute.scss";
          @import "@/assets/scss/mixins/bp.scss";
          @import "@/assets/scss/mixins/ellipsis.scss";
          @import "@/assets/scss/mixins/fixed.scss";
          @import "@/assets/scss/mixins/font.scss";
          @import "@/assets/scss/mixins/grid-gap.scss";
          @import "@/assets/scss/mixins/margin.scss";
          @import "@/assets/scss/mixins/padding.scss";
          @import "@/assets/scss/mixins/radius.scss";
          @import "@/assets/scss/mixins/relative.scss";
          @import "@/assets/scss/mixins/size.scss";
          @import "@/assets/scss/mixins/sticky.scss";
          @import "@/assets/scss/functions/rem.scss";
          @import "@/assets/scss/global/normalize.scss";
          @import "@/assets/scss/global/fonts.scss";
          @import "@/assets/scss/global/custom.scss";
        



























































































.article-preview-wrap {
  position: fixed;
  width: 100%;
  height: 60px;
  padding: 10px;
  text-align: center;
  background: #1e88e5;
}
.article-preview-toolbar {
  margin: 0 auto;
  top: 0;
  max-width: 1200px;
  text-align: center;
  span {
    font-size: 16px;
    color: #fff;
  }
}
.article-preview-iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 80px;
  width: 100%;
}
